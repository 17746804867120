<template>
  <VMenu
    instant-move
    :distance="8"
    :theme="theme"
    :placement="direction"
  >
    <slot v-if="$slots.default" />
    <IcInfo
      v-else
      :size="16"
      class="text-bb-disabled-gray hover:text-bb-brand-purple mt-1 cursor-pointer"
    />
    <template #popper>
      <div v-close-popper>
        <slot name="content" />
      </div>
    </template>
  </VMenu>
</template>

<script>
import IcInfo from '@/components/icon/ic-info.vue'

export default {
  components: { IcInfo },
  props: {
    theme: {
      type: String,
      default: 'sm',
      validator: value => ['sm', 'lg'].includes(value),
    },
    direction: {
      type: String,
      default: 'bottom',
      validator: value =>
        [
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'left',
          'left-start',
          'left-end',
          'right',
          'right-start',
          'right-end',
          'auto',
          'auto-start',
          'auto-end',
        ].includes(value),
    },
  },
  computed: {
    typeClass() {
      return `tooltip-${this.theme}`
    },
  },
}
</script>

<style>
.v-popper--theme-sm .v-popper__inner {
  padding: 10px;
  border-radius: 0;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 300px;
  box-shadow: 0px 2px 14px 0px rgba(136, 152, 170, 0.15);
  @apply text-bb-text-default bg-white rounded-lg;
}

.v-popper--theme-lg .v-popper__inner {
  padding: 10px;
  border-radius: 0;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  max-width: 500px;
  @apply text-bb-text-default bg-white rounded-lg;
}

.v-popper--theme-lg .v-popper__arrow-outer {
  box-shadow: 0px 2px 14px 0px rgba(136, 152, 170, 0.15);
  @apply border-white;
}

.v-popper--theme-sm .v-popper__arrow-outer {
  @apply border-white;
}
</style>
