<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Analysis</title>
    <path
      d="M4.5 3L4.98423 6.17782C5.05014 6.61038 5.38962 6.94986 5.82218 7.01577L9 7.5L5.82218 7.98423C5.38962 8.05014 5.05014 8.38962 4.98423 8.82218L4.5 12L4.01577 8.82218C3.94986 8.38962 3.61038 8.05014 3.17782 7.98423L0 7.5L3.17782 7.01577C3.61038 6.94986 3.94986 6.61038 4.01577 6.17782L4.5 3Z"
      fill="currentColor"
    />
    <path
      d="M9 14L9.43039 17.1776C9.48903 17.6105 9.82184 17.9552 10.2524 18.029L13 18.5L10.2524 18.971C9.82184 19.0448 9.48903 19.3895 9.43039 19.8224L9 23L8.56961 19.8224C8.51097 19.3895 8.17816 19.0448 7.74761 18.971L5 18.5L7.74761 18.029C8.17816 17.9552 8.51097 17.6105 8.56961 17.1776L9 14Z"
      fill="currentColor"
    />
    <path
      d="M15.5 0L16.3685 7.37567C16.5207 8.66891 17.4899 9.71624 18.7675 9.96817L24 11L18.7675 12.0318C17.4899 12.2838 16.5207 13.3311 16.3685 14.6243L15.5 22L14.6315 14.6243C14.4793 13.3311 13.5101 12.2838 12.2325 12.0318L7 11L12.2325 9.96817C13.5101 9.71624 14.4793 8.66891 14.6315 7.37567L15.5 0Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcStars',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
