<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.72 8.15899C14.72 7.66263 14.6755 7.18535 14.5927 6.72717H8V9.43489H11.7673C11.605 10.3099 11.1118 11.0513 10.3705 11.5476V13.304H12.6327C13.9564 12.0853 14.72 10.2908 14.72 8.15899Z"
      fill="#4285F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00002 14.9999C9.89002 14.9999 11.4746 14.3731 12.6328 13.304L10.3705 11.5477C9.74366 11.9677 8.94184 12.2159 8.00002 12.2159C6.17684 12.2159 4.63366 10.9845 4.0832 9.32996H1.74457V11.1436C2.89639 13.4313 5.26366 14.9999 8.00002 14.9999Z"
      fill="#34A853"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.08318 9.32995C3.94318 8.90995 3.86364 8.46132 3.86364 7.99995C3.86364 7.53859 3.94318 7.08996 4.08318 6.66996V4.85632H1.74455C1.27045 5.80132 1 6.87041 1 7.99995C1 9.1295 1.27045 10.1986 1.74455 11.1436L4.08318 9.32995Z"
      fill="#FBBC05"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00002 3.78409C9.02775 3.78409 9.95048 4.13727 10.6759 4.8309L12.6837 2.82318C11.4714 1.69363 9.88684 1 8.00002 1C5.26366 1 2.89639 2.56863 1.74457 4.85636L4.0832 6.66999C4.63366 5.01545 6.17684 3.78409 8.00002 3.78409Z"
      fill="#EA4335"
    />
  </svg>
</template>

<script>
export default {
  name: 'GoogleGIcon',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
