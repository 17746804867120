<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Info</title>
    <path
      d="M9.16663 5.83335H10.8333V7.50002H9.16663V5.83335ZM9.16663 9.16669H10.8333V14.1667H9.16663V9.16669ZM9.99996 1.66669C5.39996 1.66669 1.66663 5.40002 1.66663 10C1.66663 14.6 5.39996 18.3334 9.99996 18.3334C14.6 18.3334 18.3333 14.6 18.3333 10C18.3333 5.40002 14.6 1.66669 9.99996 1.66669ZM9.99996 16.6667C6.32496 16.6667 3.33329 13.675 3.33329 10C3.33329 6.32502 6.32496 3.33335 9.99996 3.33335C13.675 3.33335 16.6666 6.32502 16.6666 10C16.6666 13.675 13.675 16.6667 9.99996 16.6667Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcInfo',
  props: {
    size: {
      type: [String, Number],
      default: 20,
    },
  },
}
</script>
